import { useTranslations } from 'next-intl';

import { flightRoutes } from '@/app/[locale]/travel/flights/routes';
import { useFlightsFlag } from '@/app/[locale]/travel/use-travel-flag';
import {
  MenuItemNameSchema,
  MenuItemType,
  MenuNameType,
} from '@/config-schema/navigation';
import { RewardsCentralConfiguration } from '@/config-schema/rewards-central-config';
import { isPointsActivityEnabled } from '@/features/rewards-summary/shared/utils';
import { BootstrapData } from '@/schema/bootstrap.schema';

import {
  useLoyaltyCurrency,
  useMaxOfferPointsEarn,
} from '../bootstrap/bootstrap-hooks';
import { useFlagsReady } from '../flags-ready/use-flags-ready';
import { useUberCredit } from '../loyalty-programs/use-uber-credit';

export function useNavigationMenuData(
  config: RewardsCentralConfiguration['application']['features'],
) {
  const {
    cashback,
    giftCard,
    crypto,
    sustainability,
    pointsTransfer,
    offer,
    travelEarn,
    appleMerchandise,
    uberCredit,
    rewardsSummary,
    dining,
  } = config;

  const isM2020 = useFlagsReady('m2020_tenants');
  const isAppleMerchandiseFlagEnabled = useFlagsReady('apple-merchandise');
  const flightsFlag = useFlightsFlag();
  const isDiningOffersEnabled = useFlagsReady('dining-offers');
  const rewardsPortal2 = useFlagsReady('rewards_portal_2.0_r2');
  const enabledMarketingConsent = useFlagsReady('marketing_consent');
  const maxOfferPointsEarn = useMaxOfferPointsEarn();

  const loyaltyCurrency = useLoyaltyCurrency();
  const { uberLoyaltyProgramId } = useUberCredit({
    enabled: !!uberCredit?.enabled,
  });

  const defaultHeaderLinkMapRC2_0 = useDefaultHeaderLinkMapRC2_0(
    loyaltyCurrency,
    uberLoyaltyProgramId,
    maxOfferPointsEarn,
  );
  const defaultHeaderLinkMap = useDefaultHeaderLinkMap(
    isM2020,
    loyaltyCurrency,
    uberLoyaltyProgramId,
  );

  const headerLinkMap = rewardsPortal2
    ? defaultHeaderLinkMapRC2_0
    : defaultHeaderLinkMap;

  let routes;

  // This implicitly defines the display order of the menu items
  // eslint-disable-next-line unicorn/prefer-ternary
  if (rewardsPortal2) {
    routes = [
      pointsTransfer.enabled && MenuItemNameSchema.enum.pointsTransfer,
      flightsFlag && MenuItemNameSchema.enum.travel,
      uberCredit?.enabled && MenuItemNameSchema.enum.uberCredit,
      giftCard.enabled && MenuItemNameSchema.enum.giftCard,
      crypto.enabled && MenuItemNameSchema.enum.crypto,
      sustainability.enabled && MenuItemNameSchema.enum.sustainability,
      cashback.enabled && MenuItemNameSchema.enum.cashback,
      isAppleMerchandiseFlagEnabled &&
      appleMerchandise?.enabled &&
      MenuItemNameSchema.enum.appleMerchandise,
      offer.enabled && MenuItemNameSchema.enum.offer,
      isDiningOffersEnabled &&
      dining?.enabled &&
      MenuItemNameSchema.enum.diningOffer,
      travelEarn?.enabled && MenuItemNameSchema.enum.travelEarn,
      isM2020 && MenuItemNameSchema.enum.shopApple,
      isM2020 && MenuItemNameSchema.enum.payWithPoints,
      isM2020 && MenuItemNameSchema.enum.premiumBenefits,
      isPointsActivityEnabled(rewardsSummary) &&
      MenuItemNameSchema.enum.myPointsActivity,
      MenuItemNameSchema.enum.myRewards,
      enabledMarketingConsent && MenuItemNameSchema.enum.accountPreferences,
      MenuItemNameSchema.enum.helpCenter,
      MenuItemNameSchema.enum.termsOfUse,
      MenuItemNameSchema.enum.privacyPolicy,
      MenuItemNameSchema.enum.customerService,
      MenuItemNameSchema.enum.home,
    ] as const;
  } else {
    routes = [
      flightsFlag && MenuItemNameSchema.enum.travel,
      pointsTransfer.enabled && MenuItemNameSchema.enum.pointsTransfer,
      giftCard.enabled && MenuItemNameSchema.enum.giftCard,
      cashback.enabled && MenuItemNameSchema.enum.cashback,
      crypto.enabled && MenuItemNameSchema.enum.crypto,
      sustainability.enabled && MenuItemNameSchema.enum.sustainability,
      isAppleMerchandiseFlagEnabled &&
      appleMerchandise?.enabled &&
      MenuItemNameSchema.enum.appleMerchandise,
      offer.enabled && MenuItemNameSchema.enum.offer,
      isDiningOffersEnabled &&
      dining?.enabled &&
      MenuItemNameSchema.enum.diningOffer,
      isM2020 && MenuItemNameSchema.enum.shopApple,
      isM2020 && MenuItemNameSchema.enum.payWithPoints,
      isM2020 && MenuItemNameSchema.enum.premiumBenefits,
      travelEarn?.enabled && MenuItemNameSchema.enum.travelEarn,
      uberCredit?.enabled && MenuItemNameSchema.enum.uberCredit,
    ] as const;
  }

  // setup enabled features by default
  return {
    defaultHeaderLinkMap: headerLinkMap,
    routes,
  };
}

export const useDefaultHeaderLinkMap = (
  isM2020: boolean,
  loyaltyCurrency?: BootstrapData['loyaltyCurrencies'][0],
  uberLoyaltyProgramId?: string,
) => {
  const t = useTranslations('defaultHeaderLinkMap');

  const cashbackTitleText = t('cashbackTitle');
  const giftCardsTitleText = t('giftCardsTitle');
  const cryptoTitleText = t('cryptoTitle');
  const carbonOffsetTitleText = t('carbonOffsetTitle');
  const pointsTransferTitleText = t('pointsTransferTitle');
  const frequentTravelerProgramsTitleText = t('frequentTravelerProgramsTitle');
  const travelTitleText = t('travelTitle');
  const offersTitleText = t('offersTitle');
  const diningOffersTitleText = t('diningOffersTitle');
  const uberCreditTitleText = t('uberCreditTitle');
  const travelEarnTitleText = t('travelEarnTitle');
  const appleMerchandiseTitleText = t('appleMerchandiseTitle');
  const shopAppleTitleText = t('shopAppleTitle');
  const payWithPointsTitleText = t('payWithPointsTitle');
  const premiumBenefitsTitleText = t('premiumBenefitsTitle');

  const cashbackDescriptionText = t('cashbackDescription');
  const giftCardsDescriptionText = t('giftCardsDescription');
  const cryptoDescriptionText = t('cryptoDescription');
  const carbonOffsetDescriptionText = t('carbonOffsetDescription');
  const pointsTransferDescriptionText = t('pointsTransferDescription');
  const travelDescriptionText = t('travelDescription');
  const offersDescriptionText = t('offersDescription');
  const diningOffersDescriptionText = t('diningOffersDescription');
  const uberCreditDescriptionText = t('uberCreditDescription');
  const travelEarnDescriptionText = t('travelEarnDescription');
  const appleMerchandiseDescriptionText = t(
    'appleMerchandiseDescription_currency',
    {
      currency: loyaltyCurrency?.name || 'points',
    },
  );

  const shopAppleDescriptionText = t('shopAppleDescription');
  const payWithPointsDescriptionText = t('payWithPointsDescription');
  const premiumBenefitsDescriptionText = t('premiumBenefitsDescription');

  return new Map<MenuNameType, MenuItemType>([
    [
      'cashback',
      {
        href: '/products/cashback',
        translation: cashbackTitleText,
        shortDescription: cashbackDescriptionText,
        iconName: 'money-bill',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'cashback',
      },
    ],
    [
      'giftCard',
      {
        href: '/products/gift-cards',
        translation: giftCardsTitleText,
        shortDescription: giftCardsDescriptionText,
        iconName: 'gift',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'giftCard',
      },
    ],
    [
      'crypto',
      {
        href: '/products/crypto',
        translation: cryptoTitleText,
        shortDescription: cryptoDescriptionText,
        iconName: 'wallet',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'crypto',
      },
    ],
    [
      'sustainability',
      {
        href: '/products/sustainability',
        translation: carbonOffsetTitleText,
        shortDescription: carbonOffsetDescriptionText,
        iconName: 'leaf',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'sustainability',
      },
    ],
    [
      'pointsTransfer',
      {
        href: '/products/frequent-traveler-programs',
        translation: isM2020
          ? pointsTransferTitleText
          : frequentTravelerProgramsTitleText,
        shortDescription: pointsTransferDescriptionText,
        iconName: 'plane-departure',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'pointsTransfer',
      },
    ],
    [
      'travel',
      {
        href: flightRoutes.home,
        translation: travelTitleText,
        shortDescription: travelDescriptionText,
        iconName: 'suitcase-rolling',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'travel',
      },
    ],
    [
      'offer',
      {
        href: '/products/offers',
        translation: offersTitleText,
        shortDescription: offersDescriptionText,
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'offer',
      },
    ],
    [
      'diningOffer',
      {
        href: '/products/dining',
        translation: diningOffersTitleText,
        shortDescription: diningOffersDescriptionText,
        iconName: 'utensils',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'diningOffer',
      },
    ],
    [
      'uberCredit',
      {
        href: `/products/uber-credit/${uberLoyaltyProgramId ?? ''}`,
        translation: uberCreditTitleText,
        shortDescription: uberCreditDescriptionText,
        iconName: 'uber',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'uberCredit',
      },
    ],
    [
      'travelEarn',
      {
        href: flightRoutes.home,
        translation: travelEarnTitleText,
        shortDescription: travelEarnDescriptionText,
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'travelEarn',
      },
    ],
    [
      'appleMerchandise',
      {
        href: 'https://abcrewards.us.kaligo-staging.xyz/auth/apple', // this would be just /auth/apple after integration
        translation: appleMerchandiseTitleText,
        shortDescription: appleMerchandiseDescriptionText,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'appleMerchandise',
      },
    ],
    [
      'shopApple',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65920&viewport=-225%2C-497%2C0.04&t=IX5lWMynLZCjYymt-0&scaling=min-zoom&starting-point-node-id=2062%3A65920&hide-ui=1',
        translation: shopAppleTitleText,
        shortDescription: shopAppleDescriptionText,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'shopApple',
      },
    ],
    [
      'payWithPoints',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-66637&viewport=-1930%2C-4828%2C0.11&t=YLmT9N0ef56Xd9AQ-8&scaling=min-zoom&starting-point-node-id=2062%3A66637&hide-ui=1',
        translation: payWithPointsTitleText,
        shortDescription: payWithPointsDescriptionText,
        iconName: 'sack-dollar',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'payWithPoints',
      },
    ],
    [
      'premiumBenefits',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65454&viewport=-225%2C-497%2C0.04&t=Gshscv4QMwa5NgIp-8&scaling=min-zoom&starting-point-node-id=2062%3A65454&hide-ui=1',
        translation: premiumBenefitsTitleText,
        shortDescription: premiumBenefitsDescriptionText,
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'premiumBenefits',
      },
    ],
  ]);
};

export const useDefaultHeaderLinkMapRC2_0 = (
  loyaltyCurrency?: BootstrapData['loyaltyCurrencies'][0],
  uberLoyaltyProgramId?: string,
  maxOfferPointsEarn?: number,
) => {
  const t = useTranslations('defaultHeaderLinkMapRC2_0');

  const cashbackTitleText = t('cashbackTitle');
  const giftCardsTitleText = t('giftCardsTitle');
  const cryptoTitleText = t('cryptoTitle');
  const carbonTitleText = t('carbonTitle');
  const transfersTitleText = t('transfersTitle');
  const travelTitleText = t('travelTitle');
  const offersTitleText = t('offersTitle');
  const diningTitleText = t('diningTitle');
  const uberTitleText = t('uberTitle');
  const travelEarnTitleText = t('travelEarnTitle');
  const appleMerchandiseTitleText = t('appleMerchandiseTitle');
  const shopAppleTitleText = t('shopAppleTitle');
  const payWithPointsTitleText = t('payWithPointsTitle');
  const premiumBenefitsTitleText = t('premiumBenefitsTitle');
  const myPointsActivityTitleText = t('myPointsActivityTitle');
  const myRewardsTitleText = t('myRewardsTitle');
  const accountPreferencesTitleText = t('accountPreferencesTitle');
  const helpCenterTitleText = t('helpCenterTitle');
  const termsOfUseTitleText = t('termsOfUseTitle');
  const customerServiceSpecificTermsTitleText = t(
    'customerServiceSpecificTermsTitle',
  );
  const privacyPolicyTitleText = t('privacyPolicyTitle');
  const homeTitleText = t('homeTitle');

  const cashbackDescriptionText = t('cashbackDescription');
  const giftCardsDescriptionText = t('giftCardsDescription');
  const cryptoDescriptionText = t('cryptoDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const carbonDescriptionText = t('carbonDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const pointsTransferDescriptionText = t('pointsTransferDescription');
  const travelDescriptionText = t('travelDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const offersDescriptionText = t('offersDescription_maxOfferPointsEarn', {
    maxOfferPointsEarn: maxOfferPointsEarn || 100,
  });
  const diningDescriptionText = t('diningDescription_maxOfferPointsEarn', {
    maxOfferPointsEarn: maxOfferPointsEarn || 100,
  });
  const uberDescriptionText = t('uberDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const travelEarnDescriptionText = t('travelEarnDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const appleDescriptionText = t('appleDescription_currency', {
    currency: loyaltyCurrency?.name || 'points',
  });
  const shopAppleDescriptionText = t('shopAppleDescription');
  const payWithPointsDescriptionText = t('payWithPointsDescription');
  const premiumBenefitsDescriptionText = t('premiumBenefitsDescription');
  const myPointsActivityDescriptionText = t(
    'myPointsActivityDescription_currency',
    { currency: loyaltyCurrency?.name || 'points' },
  );
  const myRewardsDescriptionText = t('myRewardsDescription');
  const accountPreferencesDescriptionText = t('accountPreferencesDescription');
  const helpCenterDescriptionText = t('helpCenterDescription');
  const termsOfUseDescriptionText = t('termsOfUseDescription');
  const customerServiceSpecificTermsDescriptionText = t(
    'customerServiceSpecificTermsDescription',
  );
  const privacyPolicyDescriptionText = t('privacyPolicyDescription');
  const homeDescriptionText = t('homeDescription');

  return new Map<MenuNameType, MenuItemType>([
    [
      'cashback',
      {
        href: '/products/cashback',
        translation: cashbackTitleText,
        shortDescription: cashbackDescriptionText,
        iconName: 'money-bill',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'cashback',
      },
    ],
    [
      'giftCard',
      {
        href: '/products/gift-cards',
        translation: giftCardsTitleText,
        shortDescription: giftCardsDescriptionText,
        iconName: 'gift',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'giftCard',
      },
    ],
    [
      'crypto',
      {
        href: '/products/crypto',
        translation: cryptoTitleText,
        shortDescription: cryptoDescriptionText,
        iconName: 'coins',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'crypto',
      },
    ],
    [
      'sustainability',
      {
        href: '/products/sustainability',
        translation: carbonTitleText,
        shortDescription: carbonDescriptionText,
        iconName: 'leaf',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'sustainability',
      },
    ],
    [
      'pointsTransfer',
      {
        href: '/products/frequent-traveler-programs',
        translation: transfersTitleText,
        shortDescription: pointsTransferDescriptionText,
        iconName: 'rotate',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'pointsTransfer',
      },
    ],
    [
      'travel',
      {
        href: flightRoutes.home,
        translation: travelTitleText,
        shortDescription: travelDescriptionText,
        iconName: 'suitcase-rolling',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'travel',
      },
    ],
    [
      'offer',
      {
        href: '/products/offers',
        translation: offersTitleText,
        // TODO(Khoi): Update this rate to be dynamic
        shortDescription: offersDescriptionText,
        iconName: 'tags',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'offer',
      },
    ],
    [
      'diningOffer',
      {
        href: '/products/dining',
        translation: diningTitleText,
        shortDescription: diningDescriptionText,
        iconName: 'utensils',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'diningOffer',
      },
    ],
    [
      'uberCredit',
      {
        href: `/products/uber-credit/${uberLoyaltyProgramId ?? ''}`,
        translation: uberTitleText,
        shortDescription: uberDescriptionText,
        iconName: 'uber',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'uberCredit',
      },
    ],
    [
      'travelEarn',
      {
        href: flightRoutes.home,
        translation: travelEarnTitleText,
        shortDescription: travelEarnDescriptionText,
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'travelEarn',
      },
    ],
    [
      'appleMerchandise',
      {
        href: '/auth/apple',
        translation: appleMerchandiseTitleText,
        shortDescription: appleDescriptionText,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'appleMerchandise',
      },
    ],
    [
      'shopApple',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65920&viewport=-225%2C-497%2C0.04&t=IX5lWMynLZCjYymt-0&scaling=min-zoom&starting-point-node-id=2062%3A65920&hide-ui=1',
        translation: shopAppleTitleText,
        shortDescription: shopAppleDescriptionText,
        iconName: 'apple',
        iconHref: '/assets/icon/sprites/brands.svg',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'shopApple',
      },
    ],
    [
      'payWithPoints',
      {
        // href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-66637&viewport=-1930%2C-4828%2C0.11&t=YLmT9N0ef56Xd9AQ-8&scaling=min-zoom&starting-point-node-id=2062%3A66637&hide-ui=1',
        href: '/products/pay-with-points',
        translation: payWithPointsTitleText,
        shortDescription: payWithPointsDescriptionText,
        iconName: 'sack-dollar',
        groupType: 'redeem',
        iconVisible: true,
        itemType: 'payWithPoints',
      },
    ],
    [
      'premiumBenefits',
      {
        href: 'https://www.figma.com/proto/dq6tV1PpgtmZcfbCPA3odP/Money-20%2F20-%E2%80%93%C2%A0Demo-Experience?type=design&node-id=2062-65454&viewport=-225%2C-497%2C0.04&t=Gshscv4QMwa5NgIp-8&scaling=min-zoom&starting-point-node-id=2062%3A65454&hide-ui=1',
        translation: premiumBenefitsTitleText,
        shortDescription: premiumBenefitsDescriptionText,
        iconName: 'gift',
        groupType: 'earn',
        iconVisible: true,
        itemType: 'premiumBenefits',
      },
    ],
    [
      'myPointsActivity',
      {
        href: '/rewards-summary/points-activity',
        translation: myPointsActivityTitleText,
        shortDescription: myPointsActivityDescriptionText,
        groupType: 'myAccount',
        iconVisible: false,
        itemType: 'myPointsActivity',
      },
    ],
    [
      'myRewards',
      {
        href: '/rewards-summary/my-rewards',
        translation: myRewardsTitleText,
        shortDescription: myRewardsDescriptionText,
        groupType: 'myAccount',
        iconVisible: false,
        itemType: 'myRewards',
      },
    ],
    [
      'accountPreferences',
      {
        href: '/account-preferences',
        translation: accountPreferencesTitleText,
        shortDescription: accountPreferencesDescriptionText,
        groupType: 'myAccount',
        iconVisible: false,
        itemType: 'accountPreferences',
      },
    ],
    [
      'helpCenter',
      {
        href: '/help-center',
        translation: helpCenterTitleText,
        shortDescription: helpCenterDescriptionText,
        groupType: 'needHelp',
        iconVisible: false,
        itemType: 'helpCenter',
      },
    ],
    [
      'termsOfUse',
      {
        href: '/help-center/terms-and-conditions',
        translation: termsOfUseTitleText,
        shortDescription: termsOfUseDescriptionText,
        groupType: 'needHelp',
        iconVisible: false,
        itemType: 'termsOfUse',
      },
    ],
    [
      'privacyPolicy',
      {
        href: '/help-center/privacy-policy',
        translation: privacyPolicyTitleText,
        shortDescription: privacyPolicyDescriptionText,
        groupType: 'needHelp',
        iconVisible: false,
        itemType: 'privacyPolicy',
      },
    ],
    [
      'customerService',
      {
        href: '/help-center/customer-service-specific-terms',
        translation: customerServiceSpecificTermsTitleText,
        shortDescription: customerServiceSpecificTermsDescriptionText,
        groupType: 'needHelp',
        iconVisible: false,
        itemType: 'customerService',
      },
    ],
    [
      'home',
      {
        href: '/',
        translation: homeTitleText,
        shortDescription: homeDescriptionText,
        groupType: 'home',
        iconName: 'house-chimney',
        iconVisible: true,
        itemType: 'home',
      },
    ],
  ]);
};
